<template>
  <v-dialog
    v-model="view.toggle"
    scrollable
    transition="slide-y-reverse-transition"
  >
    <v-card>
      <v-card-title>Esqueci a senha</v-card-title>
      <v-divider />
      <v-card-text class="py-4">
        <p class="body-2">
          Informe seu email abaixo e te enviaremos um link para recuperar a senha.
        </p>

        <v-text-field 
          v-model="email.input" 
          :label="email.label" 
          :disabled="email.disabled"
          :loading="email.loading"
          :error-messages="email.error"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary darken-1"
          text
          @click="view.toggle = false;"
        >
          Voltar
        </v-btn>
        <v-btn
          color="primary darken-1"
          text
          :disabled="btn.disabled"
          @click="handleForm()"
        >
          Recuperar senha
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>

</style>

<script>
  // Utilities
  import { sync } from 'vuex-pathify'

  export default {
    name: 'ForgotPassword',

    data: () => ({
      email: {
        label: 'Email',
        input: '',
        disabled: false,
        loading: false,
        error: []
      },
      btn: {
        disabled: false,
      },
    }),

    watch: {
    },

    computed: {
      view: sync('login/views@forgotPassword'),
    },

    methods: {
      handleForm () {
        if (this.email.input!==''&&/.+@.+\..+/.test(this.email.input)) {
          // validate TODO
          this.recoverPassword();
          this.email.error = [];
          console.log('feedback');
        }else{
          // validate TODO
          console.log(this.email.input);
          this.email.error.push('Email inválido');
        }
      },

      recoverPassword () {
        // server call mock
        this.email.loading = true;
        setTimeout(this.success,2000);
          
      },
      success () {
        // show feedback msg
        this.view.toggle = this.email.loading = false;
        this.view.feedback.text = 'Pronto! Um link foi enviado para você, verifique seu email.';
        this.view.feedback.toggle = true;
      },
    },

    mounted () {
    },

  }
</script>
